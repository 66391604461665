<template>
    <div>
        <app-layout>
	        <template v-slot:header>
	            <Header :title="$t('placement_exams')"
	                    :isNewButton="checkPermission('placementexam_store')"
	                    :isColumns="true"
	                    @new-button-click="createFormModalShow()"
	                    @filter-div-status="datatable.filterStatus=$event"
	            >
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('placement_exams')"
		                      :isNewButton="checkPermission('placementexam_store')"
		                      :isColumns="true"
		                      @new-button-click="createFormModalShow()"
		                      @filter-div-status="datatable.filterStatus=$event"
	            >
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </HeaderMobile>
	        </template>

            <!-- Datatable -->
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="3" lg="3">
                        <b-form-group :label="$t('id')">
                            <b-form-input type="number" v-model="datatable.queryParams.filter.id"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="3">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="datatable.queryParams.filter.academic_year">
                            </academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="3">
                        <b-form-group :label="$t('type')">
                            <b-form-select v-model="datatable.queryParams.filter.type"
                                           :options="types">
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="3">
                        <b-form-group :label="$t('type')">
                            <b-form-select v-model="datatable.queryParams.filter.exam_type"
                                           :options="examTypes">
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>

            <!-- Modal -->
            <CommonModal ref="formModal" size="xxl" :onHideOnlyX="true" @bHideModal="clearData">
                <template v-slot:CommonModalTitle>
                    <div v-if="formProgress=='create'">{{ $t('new') }}</div>
                    <div v-if="formProgress=='update'">{{ $t('edit') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <create-update-form :formId.sync="formId"
                                        :formProgress="formProgress"
                                        :types="types"
                                        :examTypes="examTypes"
                                        :registrationTypes="registrationTypes"
                                        @createFormSuccess="createFormSuccess($emit)"
                                        @updateFormSuccess="updateFormSuccess($emit)"
                                        :key="createUpdateFormKey"
                    >
                    </create-update-form>
                </template>
            </CommonModal>
            <CommonModal ref="partModal" size="xl" :onHideOnlyX="true" @bHideModal="clearData">
                <template v-slot:CommonModalTitle>
                    {{ $t('exam_parts') }}
                </template>
                <template v-slot:CommonModalContent>
                    <placement-exam-part-index :placementExamId="placementExamId"
                                               :key="placementExamPartComponentKey"
                                               v-if="placementExamId"
                    >
                    </placement-exam-part-index>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>

<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
    import DatatableFilter from "@/components/datatable/DatatableFilter";
    import Datatable from "@/components/datatable/Datatable";
    import CommonModal from "@/components/elements/CommonModal";
    import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
    import CreateUpdateForm from "./CreateUpdateForm";
    import placementExamPartIndex from "@/modules/placementExamParts/pages/Index";

    import qs from 'qs'

    // Services
    import PlacementExamService from "@/services/PlacementExamService"
    import ParameterService from "@/services/ParameterService";

    // Component
    export default {
        components: {
            AppLayout,
            Header,
	        HeaderMobile,
            DatatableFilter,
            Datatable,
            CommonModal,
            AcademicYearsSelectbox,
            CreateUpdateForm,
            placementExamPartIndex,
        },
        metaInfo() {
            return {
                title: this.$t('placement_exams')
            }
        },
        data() {
            return {
                // Datatable
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('exam_parts'),
                                    class: 'ri-play-list-add-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "placementexampart_index",
                                    callback: (row) => {
                                        this.showPartModal(row.id)
                                    },
                                    show: function (row) {
                                        if(row.type=='proficiency'){
                                            return true;
                                        }
                                        return false;
                                    }
                                },
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "placementexam_update",
                                    callback: (row) => {
                                        this.updateFormModalShow(row.id)
                                    },
                                    show: function (row) {
                                        return true;
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "placementexam_delete",
                                    callback: (row) => {
                                        this.deleteForm(row.id)
                                    },
                                    show: function (row) {
                                        return true;
                                    }
                                },
                                {
                                    text: this.$t("classrooms"),
                                    class: "ri-list-check ",
                                    permission: "placementexamclassroom_show",
                                    callback: (row) => {
                                        this.id = row.id;
                                        this.$router.push("/placement/exam/classrooms/" + this.id);
                                    },
                                },
                            ]
                        },
                        {
                            label: this.$t('id'),
                            field: 'id',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('academic_year'),
                            field: 'academic_year',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('name'),
                            field: ('name'),
                            sortable: false,
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('type'),
                            field: 'type',
                            hidden: false,
                            sortable: false,
                            formatFn: (value) => {
                                return this.getPlacementType(value)
                            }
                        },
                        {
                            label: this.toUpperCase('exam_type'),
                            field: 'exam_type',
                            hidden: false,
                            sortable: false,
                            formatFn: (value) => {
                                return this.getPlacementExamType(value)
                            }
                        },
                        {
                            label: this.toUpperCase('date'),
                            field: 'date',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('start_time').replace(' ', '<br>'),
                            field: 'start_time',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                            sortable: false,
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('end_time').replace(' ', '<br>'),
                            field: 'end_time',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                            sortable: false,
                            hidden: false,
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        page: 1,
                        limit: 20,
                        order: '-id'
                    }
                },

                // Form
                formId: null,
                formProgress: null,

                // Data
                types: [
                    {value: null, text: this.$t('select')},
                    {value: 'placement', text: this.$t('placement_exam')},
                    {value: 'proficiency', text: this.$t('proficiency_exam')},
                ],
                examTypes: [
                    {value: null, text: this.$t('select')},
                    {value: 'in_class', text: this.$t('in_class')},
                    {value: 'online', text: this.$t('online')},
                    {value: 'hybrid', text: this.$t('hybrid')},
                ],
                registrationTypes: [],
                placementExamId:null,

                // Component Keys
                createUpdateFormKey: 'createUpdateFormKey-1',
                placementExamPartComponentKey: 'placementExamPartComponentKey-1'
            }
        },
        created() {
            this.filterSet()
            this.clearData();
            this.getRegistrationTypes();
        },
        methods: {
            // Datatable
            filterSet() {
                this.datatable.queryParams.filter = {
                    id: null,
                    academic_year: null,
                    type: null,
                    exam_type: null,
                }
            },
            filterClear() {
                this.filterSet()
                this.getRows();
            },
            filter() {
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return PlacementExamService.getAll(config)
                                           .then((response) => {
                                               this.datatable.rows = response.data.data
                                               this.datatable.total = response.data.pagination.total
                                           })
                                           .finally(() => {
                                               this.datatable.isLoading = false;
                                           });
            },

            // Form Clear
            clearData() {
                this.formId = null;
                this.formProgress = null
                this.placementExamId = null;
            },

            // Create
            createFormModalShow() {
                this.clearData();
                this.formProgress = 'create';  this.createUpdateFormKey = 'createUpdateFormKey-'+Math.floor((Math.random() * 1000) + 1);

                this.$refs.formModal.$refs.commonModal.show();
            },
            createFormSuccess(result) {
                this.getRows();
                this.$refs.formModal.$refs.commonModal.hide()
            },

            // Update
            updateFormModalShow(id) {
                this.formId = id;
                this.formProgress = 'update';
                this.createUpdateFormKey = 'createUpdateFormKey-'+Math.floor((Math.random() * 1000) + 1);
                this.$refs.formModal.$refs.commonModal.show();
            },
            updateFormSuccess() {
                this.clearData();
                this.getRows();
                this.$refs.formModal.$refs.commonModal.hide()
            },

            // Delete
            deleteForm(id) {
                this.$swal.fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            PlacementExamService.del(id)
                                                .then(response => {
                                                    this.$toast.success(this.$t('api.' + response.data.message));
                                                    this.getRows();
                                                    this.clearData();
                                                })
                                                .catch(error => {
                                                    this.$toast.error(this.$t('api.' + error.data.message));
                                                });
                        }
                    })
            },

            // Parts
            showPartModal(id) {
                this.placementExamId = id;
                this.placementExamPartComponentKey = 'placementExamPartComponentKey-'+Math.floor((Math.random() * 1000) + 1);
                this.$refs.partModal.$refs.commonModal.show();
            },

            // Registration Types
            getRegistrationTypes() {
                this.registrationTypes = [];
                ParameterService.getItems('registration_types')
                                .then(response => {
                                    const data = response.data.data.items;
                                    data.map(item => {
                                        this.registrationTypes.push({
                                            id: item.code,
                                            name: item.name
                                        });
                                    });
                                });
            },
        }
    }
</script>
