import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/placement/exams', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/placement/exams/' + id);
}

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/placement/exams', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/placement/exams/' + id, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/placement/exams/' + id);
}

const downloadFile = async (uuid) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/placement/exams/download/' + uuid, {responseType: 'arraybuffer'});
}

export default {
    getAll,
    get,
    store,
    update,
    del,
    downloadFile
}
